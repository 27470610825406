import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import { Button, Card } from 'react-bootstrap'
import '../network/network.css'
import { RiArrowLeftLine } from "react-icons/ri";
import searchIcon from '../../../static/images/Icon feather-search.png';
import deleteIcon from '../../../static/images/deleteIcon.png';
import plusIcon from '../../../static/images/plus.png';
import deletesIcon from '../../../static/images/delete.png';
import Seo from "../../components/seo";
import { Link } from 'gatsby';
import message from '../../../static/images/message.png';
import messageIconnetwork from '../../../static/images/messageIconnetwork.png'
import { groupMembers, joinGroupPendingList } from '../../components/Api/ListingApi';
import AvatarImage from '../../components/Avatar/avatarImage';
import { ToastContainer } from 'react-toastify';
import DeleteAllgroupMemberPopup from '../../components/popup/DeleteAllgroupmemberPopup'
import SelectMemberPopup from './SelectMemberPopup';
import { useSelector } from 'react-redux';
import DeleteMemberPopup from './DeleteMemberPopup';
import { StreamChat } from "stream-chat";
import "stream-chat-react/dist/css/index.css";
import ChatPopup from "../../components/popup/ChatPopup"
import Loader from '../../components/Loader'
import ChatGroupPopup from "../../components/popup/ChatGroupPopup"
import { useLocation } from '@reach/router';
import Skeleton from 'react-loading-skeleton';
import SkeletonHorizondalCard from '../../components/card/skeletonHorizondalCard';

export default function Network() {
  const location = useLocation();
  const netWorkDetails = location.state.groupname
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState(false)
  const toggleDelete = () => setShowData(p => !p)
  const fileName = useSelector(state => state.dashboard.netWork_fileName)
  // const groupName = useSelector(state => state.dashboard.netWork_Details)
  const [deleteMember, setDeleteMember] = useState({})
  const [showDelete, setShowDelete] = useState(false)
  const toggle = () => setShowDelete(p => !p)
  const [showSelect, setShowSelect] = useState(false);
  const [chatAgent, setChatAgent] = useState({})
  const [chatUser, setChatUser] = useState({})
  const [chatId, setChatId] = useState("")
  const userDetails = useSelector(state => state.user.userDetail);
  const chattingUserData = useSelector(state => state.dashboard.chat_disconnect);
  const [loader, setLoader] = useState(false);
  const [loaders, setLoaders] = useState(false);
  const [openChat, setOpenChat] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const toggleShowChat = () => setShowChat(p => !p)
  const [pendingList, setPendingList] = useState([])
  const array = [1, 2, 3, 4, 5, 6]

  // console.log("netWorkDetails", netWorkDetails)
  const showChatDetail = () => {
    if (openChat === false) {
      setLoaders(true);
    }
    else {
      setLoaders(false);

    }
    setOpenChat(p => !p)
  }

  const toggleSelect = () => setShowSelect(p => !p);
  let grp_id = localStorage.getItem('id')
  let userId = localStorage.getItem('userId')


  useEffect(() => {
    viewGroupMembers();
    getGroouptList()
  }, []);

  const viewGroupMembers = async () => {
    let id = grp_id;
    setLoader(true)
    await groupMembers(id).then((res) => {
      res.data.data.members.map((i => i.isActive = false))
      setData(res.data.data.members)
      // setLoader(false)

    })
  }
  //pending member list//
  const getGroouptList = async () => {
    let getParams = {
      group_id: netWorkDetails._id
    }
    setLoader(true)
    await joinGroupPendingList(getParams).then((res) => {
      setPendingList(res.data.data)
      // console.log("list response", res.data.data)
      setLoader(false)

    }).catch(error => {
      console.log(error)
    })
  }

  const ApiData = () => {
    for (var i = 0; i < data.length; i++) {
      localStorage.setItem("agentname", data[i].name)
    }
  }
  ApiData();


  const handleClick = (index, name, id, e) => {


    let result = [...data]
    result[index].isActive = !result[index].isActive
    setData(result)
  }
  const handleSelect = () => {
    let result = [...data]
    data.map((item) => (
      item.isActive = true

    ))
    setData(result)
  }

  const hanldeImage = (img) => {
    let data = img !== null && img.split('/')[0]
    if (data === "uploads") {
      return process.env.API_IMAGE_URL + img
    }
    else {
      return img
    }
  }
  const handleChat = async (id, name, lastName, image) => {
    let names = name
    await chattingUserData !== null && chattingUserData.disconnectUser()
    const apikey = process.env.STREAM_CHAT_APIKEY;

    const chatClient = StreamChat.getInstance(apikey);
    await chatClient.connectUser({ id: id, name: names, image: image }, chatClient.devToken(id));
    await chatClient.disconnectUser()

    setChatAgent({ id: id, name: names, image: image })
    setChatUser({ id: userDetails._id, name: userDetails.name, image: hanldeImage(userDetails.img) })
    setChatId(id + userDetails._id)
    showChatDetail()
  }

  return (
    <Layout>
     {loaders? <Loader/> :null}
      < ToastContainer />
      <DeleteMemberPopup showDelete={showDelete} toggle={toggle} item={deleteMember} reload={viewGroupMembers} />
      <DeleteAllgroupMemberPopup showData={showData} toggleDelete={toggleDelete} data={data} reload={viewGroupMembers} />
      <SelectMemberPopup showSelect={showSelect} toggleSelect={toggleSelect} chatDetails={netWorkDetails} />
      {showChat && <ChatGroupPopup showChat={showChat} toggleShowChat={toggleShowChat} netWorkDetails={netWorkDetails} />}
      < ToastContainer />
      <Seo title="Network" />
      <div className='searchBuy networkSearch'>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ padding: '0px 15px' }}>
            <form>
              <input className="buyer-search" placeholder='Search' />
              <div className="searchIconAgent" >
                <img src={searchIcon} alt="Icon feather-search.png" className='search-icon-img' />
              </div>
            </form>
          </div>

        </div>
      </div>
      <div>
        <Link to="/network" style={{ textDecoration: "none" }}>
          <div className='networkbackicon NetworkBackIcon'>
            <RiArrowLeftLine style={{ fill: '' }} size="25px" className='NetworkArrowLine' />
            <p className='backtext m-0 ps-1'>Back</p>
          </div>
        </Link>
        <div className="networkdethead">
          <p className='networkselect' onClick={handleSelect} onKeyPress={handleSelect} aria-hidden>Select All</p>
          <h5 className='networkheading'>{fileName}</h5>
          <div className='networkdetbut'>
            <div className='position-relative'>
              <Button className='networkdelete' onClick={toggleDelete} >
                Delete
              </Button>
              <img src={deleteIcon} alt="deleteIcon" className='networkdeleteIcon' />
            </div>
            <div className='position-relative'>
              <Button className='networkmessage' onClick={toggleShowChat} >Message</Button>
              <img src={messageIconnetwork} alt="messageIconnetwork" className='messageIconnetwork' />
            </div>
            <div>
              <img src={plusIcon} alt="plus.png" className='pluses-icon-img' />
              <Button className='networkdetadd' onClick={() => { toggleSelect(); }}>Add New Member</Button>
            </div>
          </div>
        </div>
        <Card className='networkdetailCard'>
          {loader ?
            array.map((item) => <SkeletonHorizondalCard purpose="networkdetail" />)
            :
            data.map((item, index) =>
              <div className='row networkcenter' key={index}>

                <div className="col-md-1">
                  {userId !== item._id &&
                    <label className="checkbox" style={{cursor:"pointer"}}>
                      <input type="checkbox" className="checkbox__input" checked={item.isActive} onChange={(e) => handleClick(index, item._id, e)} style={{cursor:"pointer"}} />
                      <span className="checkbox__inner"></span>
                    </label>
                  }
                </div>
                <div className="col-md-1 ml-70">
                  {item.img ?
                    <img alt="no_image" src={process.env.API_IMAGE_URL + item.img} style={{ borderRadius: "50%", width: "88px", height: "88px" }} crossOrigin="anonymous" />
                    : <>{item.name ? <div style={{ width: '88px', height: '88px' }}><AvatarImage fontSize={'40px'} data={item.name ? item.name : ''} /></div>
                      : null}</>
                  }
                </div>
                <div className="col-md-2 ml-12">
                  <p className="danielname text-capitalize">{item.name}</p>
                </div>

                <div className='col-md-3 deletemessage'>
                  <p style={{ margin: "0" }}>Company</p>
                  <p className='maridianText'>{item.Company}</p>
                </div>
                <div className='col-md-3 deletemessage'>
                  <p style={{ margin: "0" }}>Role/Title</p>
                  <p className='agentText text-capitalize'>{item.role}</p>
                </div>
                <div className='d-flex col-md-2 deletemessage'>{
                  userId !== item._id &&
                  <>
                    <img src={deletesIcon} alt="delete.png" className='deletes-icon-img' style={{ width: "15px", cursor: "pointer" }} onClick={() => { setDeleteMember(item); toggle() }} onKeyPress={() => { setDeleteMember(item); toggle() }} aria-hidden />
                    <img src={message} alt="message" onClick={() => { handleChat(item._id, item.name, item.last_name, item.img ? hanldeImage(item.img) : ""); }} style={{ marginLeft: "40px", width: "28px", cursor: "pointer" }} onKeyPress={() => { handleChat(item._id, item.name, item.last_name, item.img ? hanldeImage(item.img) : ""); }} aria-hidden />
                  </>
                }

                </div>
                <hr className='networkhrtag' />
              </div>
            )}


        </Card>
      </div>
      <>
        {/* {loader ? <Loader /> : null} */}
        {openChat && <ChatPopup show={openChat} toggleShow={showChatDetail} agent={chatAgent} user={chatUser} id={chatId} />}

      </>
      {pendingList.length !== 0 &&
        <div>
          <h6 className='mt-3 mb-2 py-2' style={{ fontFamily: "DejaVuSansBold" }}>Pending Members</h6>
          <Card className='networkdetailCard'>
            {pendingList.map((item, index) =>
              <div className='row networkcenter' key={index}>

                <div className="col-md-1">
                  {/* {userId !== item._id &&
                  <label className="checkbox">
                    <input type="checkbox" className="checkbox__input" checked={item.isActive} onChange={(e) => handleClick(index, item._id, e)} />
                    <span className="checkbox__inner"></span>
                  </label>
                } */}
                </div>
                <div className="col-md-1 ml-70">
                  {item.receiver !== null && item.receiver.img ?
                    <img alt="no_image" src={process.env.API_IMAGE_URL + item.receiver.img} style={{ borderRadius: "50%", width: "88px", height: "88px" }} crossOrigin="anonymous" />
                    : <>{item.receiver !== null && item.receiver.name ? <div style={{ width: '88px', height: '88px' }}><AvatarImage fontSize={'40px'} data={item.receiver.name ? item.receiver.name : ''} /></div>
                      : null}</>
                  }
                </div>
                <div className="col-md-2 ml-12">
                  <p className="danielname text-capitalize">{item.receiver !== null && item.receiver.name}</p>
                </div>

                <div className='col-md-3 deletemessage'>
                  <p style={{ margin: "0" }}>Status</p>
                  <p className='maridianText'>{item.status}</p>
                </div>
                {/* <div className='col-md-3 deletemessage'>
                <p style={{ margin: "0" }}>Role/Title</p>
                <p className='agentText text-capitalize'>{item.role}</p>
              </div> */}
                {/* <div className='d-flex col-md-2 deletemessage'>{
                userId !== item._id &&
                <>
                  <img src={deletesIcon} alt="delete.png" className='deletes-icon-img' style={{ width: "15px", cursor: "pointer" }} onClick={() => { setDeleteMember(item); toggle() }} onKeyPress={() => { setDeleteMember(item); toggle() }} aria-hidden />
                  <img src={message} alt="message" onClick={() => { handleChat(item._id, item.name, item.last_name, item.img ? hanldeImage(item.img) : ""); }} style={{ marginLeft: "40px", width: "28px", cursor: "pointer" }} onKeyPress={() => { handleChat(item._id, item.name, item.last_name, item.img ? hanldeImage(item.img) : ""); }} aria-hidden />
                </>
              }

              </div> */}
                <hr className='networkhrtag' />
              </div>
            )}


          </Card>
        </div>}
    </Layout>

  )
}