// import { Link } from "gatsby";
import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import * as style1 from '../../pages/dashboard/detail.module.css';
import { deleteGroupMember } from "../../components/Api/ListingApi";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastPopup from "../../components/popup/ToastPopup";

function DeleteMemberPopup(props) {
  const { showDelete,toggle,item,reload} = props;
  const [success, setSucess] = useState(false);
  const[messages,setMessages]=useState("")
  const toggleSuccess = () => setSucess(p => !p);
  let grp_id = localStorage.getItem('id')
let id=item._id;

  const deleteMember = async () => {
       let getParams = {
         user_id: [id],
       }
       await deleteGroupMember(grp_id, getParams).then((res) => {
           toggle()
           reload()
           setMessages(res.data.message)

        toggleSuccess()
         
       }).catch((error) => {
        console.log(error)
      })
    }
  


  return (
    <div>
       <ToastContainer />
      <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />

      <Modal show={showDelete} onHide={toggle} className='ModalDiv'>
      <div className="PopUpBorderDiv">

        <Modal.Header closeButton className="team-popup-margin teampopup-title " >
          <Modal.Title className='form-field-label form-label-team '></Modal.Title>
        </Modal.Header>
        <Modal.Body className="team-popup-margin pb-0" >
          <div>
            <div className={style1.successtext}>
              <>
                <p className={style1.accepttext}>Are you sure you want to Delete?</p>
                {/* <p className={style1.accepttext}>{para2}</p> */}
              </>
            </div>
          </div>
          <Button className={style1.nobutton} onClick={toggle}>
            No
          </Button>
          <Button className={style1.yesbutton} onClick={deleteMember}>
            Yes
          </Button>
        </Modal.Body>
        </div>
      </Modal>
    </div>
  )
}
export default DeleteMemberPopup